body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.blue {
  color: #26B4FF!important;
}

.swal2-container {
  z-index: 1000000!important;
}
.tooltip { z-index: 1151 !important; }

.find-product-container {
  height:300px;
  max-height: 300px;
  overflow: auto;
}
.find-product-container table, .find-product-container ul {
  margin-bottom:0;
}
.find-product-container table {
  height:100%;
}
.find-product-container ul li {
  height:110px;
  overflow: hidden;
}
.find-product-container tr td {
  vertical-align: middle;
}
.pac-container {
  border-radius:8px !important;
  padding:0 !important;
  font-family:-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif  !important;
}
.pac-item {
  border:none  !important;
  padding:5px 10px !important;
  font-size: 13px !important;
}
.pac-logo:after {
  background-color:#f0f0f0 !important;
  padding: 13px !important;
  background-origin: content-box !important;
}
.pac-icon {
  display:none !important;
}
.react-datepicker__day--today, .react-datepicker__month-text--today {
  font-weight:bold;
  color: var(--cyan) !important;
  background-color:inherit !important;
}
.react-datepicker__quarter-text--today, .react-datepicker__day:hover {
  background-color:#7b7b7b !important;
}
.clamp-1 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;
}
.clamp-2 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  line-clamp: 3;
  -webkit-box-orient: vertical;
}
.text-smaller {
  font-size: .75rem;
}