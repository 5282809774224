@import '../../styles/_custom-variables/libs';
@import "~perfect-scrollbar/css/perfect-scrollbar";
@import "../../styles/_appwork/include-dark";
@import "../../styles/_appwork/include-material";
@import "../../styles/_appwork/include";


$ps-scrollbar-width: .25rem !default;
$ps-scrollbar-hover-width: .375rem !default;

.ps {
  position: relative;
}

.ps__rail-x,
.ps__rail-y,
.ps__thumb-x,
.ps__thumb-y {
  border-radius: 10rem;
}

.ps__rail-x {
  height: $ps-scrollbar-width;
}

.ps__rail-y {
  width: $ps-scrollbar-width;
}

.ps__thumb-x {
  bottom: 0;
  height: $ps-scrollbar-width;
}

.ps__thumb-y {
  right: 0;
  width: $ps-scrollbar-width;
}

.ps__rail-x:hover,
.ps__rail-x:focus,
.ps__rail-x.ps--clicking,
.ps__rail-x:hover > .ps__thumb-x,
.ps__rail-x:focus > .ps__thumb-x,
.ps__rail-x.ps--clicking > .ps__thumb-x {
  height: $ps-scrollbar-hover-width;
}

.ps__rail-y:hover,
.ps__rail-y:focus,
.ps__rail-y.ps--clicking,
.ps__rail-y:hover > .ps__thumb-y,
.ps__rail-y:focus > .ps__thumb-y,
.ps__rail-y.ps--clicking > .ps__thumb-y {
  width: $ps-scrollbar-hover-width;
}

@if $enable-light-style {
  .light-style {

    .ps__rail-x:hover,
    .ps__rail-y:hover,
    .ps__rail-x:focus,
    .ps__rail-y:focus,
    .ps__rail-x.ps--clicking,
    .ps__rail-y.ps--clicking {
      background-color: $gray-200;
    }

    .ps__thumb-x,
    .ps__thumb-y {
      background-color: $gray-400;
    }

    .ps__rail-x:hover > .ps__thumb-x,
    .ps__rail-y:hover > .ps__thumb-y,
    .ps__rail-x:focus > .ps__thumb-x,
    .ps__rail-y:focus > .ps__thumb-y,
    .ps__rail-x.ps--clicking > .ps__thumb-x,
    .ps__rail-y.ps--clicking > .ps__thumb-y {
      background-color: $gray-700;
    }

    .ps-inverted {
      .ps__rail-x:hover,
      .ps__rail-y:hover,
      .ps__rail-x:focus,
      .ps__rail-y:focus,
      .ps__rail-x.ps--clicking,
      .ps__rail-y.ps--clicking {
        background-color: rgba(#fff, .5);
      }

      .ps__thumb-x,
      .ps__thumb-y {
        background-color: rgba(#fff, .7);
      }

      .ps__rail-x:hover > .ps__thumb-x,
      .ps__rail-y:hover > .ps__thumb-y,
      .ps__rail-x:focus > .ps__thumb-x,
      .ps__rail-y:focus > .ps__thumb-y,
      .ps__rail-x.ps--clicking > .ps__thumb-x,
      .ps__rail-y.ps--clicking > .ps__thumb-y {
        background-color: #fff;
      }
    }
  }
}

@if $enable-material-style {
  .material-style {

    .ps__rail-x:hover,
    .ps__rail-y:hover,
    .ps__rail-x:focus,
    .ps__rail-y:focus,
    .ps__rail-x.ps--clicking,
    .ps__rail-y.ps--clicking {
      background-color: $gray-200;
    }

    .ps__thumb-x,
    .ps__thumb-y {
      background-color: $gray-400;
    }

    .ps__rail-x:hover > .ps__thumb-x,
    .ps__rail-y:hover > .ps__thumb-y,
    .ps__rail-x:focus > .ps__thumb-x,
    .ps__rail-y:focus > .ps__thumb-y,
    .ps__rail-x.ps--clicking > .ps__thumb-x,
    .ps__rail-y.ps--clicking > .ps__thumb-y {
      background-color: $gray-700;
    }

    .ps-inverted {
      .ps__rail-x:hover,
      .ps__rail-y:hover,
      .ps__rail-x:focus,
      .ps__rail-y:focus,
      .ps__rail-x.ps--clicking,
      .ps__rail-y.ps--clicking {
        background-color: rgba(#fff, .5);
      }

      .ps__thumb-x,
      .ps__thumb-y {
        background-color: rgba(#fff, .7);
      }

      .ps__rail-x:hover > .ps__thumb-x,
      .ps__rail-y:hover > .ps__thumb-y,
      .ps__rail-x:focus > .ps__thumb-x,
      .ps__rail-y:focus > .ps__thumb-y,
      .ps__rail-x.ps--clicking > .ps__thumb-x,
      .ps__rail-y.ps--clicking > .ps__thumb-y {
        background-color: #fff;
      }
    }
  }
}

@if $enable-dark-style {
  .dark-style {

    .ps__rail-x:hover,
    .ps__rail-y:hover,
    .ps__rail-x:focus,
    .ps__rail-y:focus,
    .ps__rail-x.ps--clicking,
    .ps__rail-y.ps--clicking {
      background-color: $gray-200;
    }

    .ps__thumb-x,
    .ps__thumb-y {
      background-color: $gray-400;
    }

    .ps__rail-x:hover > .ps__thumb-x,
    .ps__rail-y:hover > .ps__thumb-y,
    .ps__rail-x:focus > .ps__thumb-x,
    .ps__rail-y:focus > .ps__thumb-y,
    .ps__rail-x.ps--clicking > .ps__thumb-x,
    .ps__rail-y.ps--clicking > .ps__thumb-y {
      background-color: $gray-700;
    }

    .ps-inverted {
      .ps__rail-x:hover,
      .ps__rail-y:hover,
      .ps__rail-x:focus,
      .ps__rail-y:focus,
      .ps__rail-x.ps--clicking,
      .ps__rail-y.ps--clicking {
        background-color: rgba(#fff, .5);
      }

      .ps__thumb-x,
      .ps__thumb-y {
        background-color: rgba(#fff, .7);
      }

      .ps__rail-x:hover > .ps__thumb-x,
      .ps__rail-y:hover > .ps__thumb-y,
      .ps__rail-x:focus > .ps__thumb-x,
      .ps__rail-y:focus > .ps__thumb-y,
      .ps__rail-x.ps--clicking > .ps__thumb-x,
      .ps__rail-y.ps--clicking > .ps__thumb-y {
        background-color: #fff;
      }
    }
  }
}
